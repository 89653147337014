import React from "react";
import { Link } from "react-router-dom";


const Contact = () => {
  return (
    <div className="contact">
      <h1>Book an Appointment</h1>
    </div>
  );
};

export default Contact;