import React from "react";
import { Link } from "react-router-dom";
import "./About.css"

const About = () => {
  return (
    <div  className="about">
      <h1>About</h1>
      {/* Add an image from the public folder */}
      <img src="/unnamed.jpeg" style={{width: "300px"}}alt="Description of image" />
      <p>Eli is a dedicated and highly qualified professional in the field of nutrition and dietetics. With a diverse educational background and extensive experience, she is committed to improving the health and well-being of her clients.</p><p>

Eli began her journey by obtaining her Bachelor of Nutrition from Isfahan University of Medical Sciences in 2003. In pursuit of further growth and opportunities, she relocated to Canada in 2005. Her degree was evaluated and recognized as equivalent to a Bachelor of Science Degree with a major in Dietetics, showcasing her exceptional knowledge and skills in the field.</p><p>

To enhance her practical expertise, Eli completed a comprehensive dietetic internship in British Columbia. This invaluable experience allowed her to refine her abilities and gain firsthand exposure to a wide range of nutritional challenges and solutions.</p><p>

Eli is a registered dietitian and a proud member of both the College of Dietitians of BC and the Dietitians of Canada. Her commitment to professional development and expanding her scope of practice led her to acquire an American degree with the Commission on Dietetic Registration in the United States, further solidifying her expertise and credentials.</p><p>

With over 15 years of experience in the field, Eli has cultivated a deep understanding of nutrition and dietetics, enabling her to provide evidence-based guidance and support to her clients. Her specialization lies in weight management, and she is dedicated to helping individuals achieve their nutrition and health goals. Eli's passion for her work shines through as she works closely with clients, empowering them to make informed choices and adopt sustainable lifestyle changes.</p><p>

Eli's compassionate approach and comprehensive knowledge make her a valuable asset in the pursuit of better health and improved nutrition. She is committed to providing personalized care and guidance, tailoring her strategies to suit the unique needs and circumstances of each client. With Eli by their side, individuals can embark on a transformative journey toward a healthier and more fulfilling life.</p>
    </div>
  );
};

export default About;
