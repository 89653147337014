import React from "react";
import "./Services.css"; // Import CSS for styling

const Services = () => {
  return (
    <div className="services">
      <h1>Services</h1>
      <div className="services-container">
        <div className="service-box">Life Style Modification</div>
        <div className="service-box">Weight Management</div>
        <div className="service-box">Diabetes</div>
        
      </div>
      <div className="services-container">
      <div className="service-box">Healthy Heart</div>
        <div className="service-box">Hypertension</div>
        <div className="service-box">Renal Disease</div>
        
      </div>
    </div>
  );
};

export default Services;
