import React from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css"; // Sidebar styles

const Sidebar = () => {
  return (
    <div className="sidebar">
      <Link to="/"><h1>Eli Diet</h1></Link>
      <ul>
        
        <li><Link to="/about">About</Link></li>
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/blogs">Blogs</Link></li>
        <li><Link to="/contact">Book Appointment</Link></li>
      </ul>
    </div>
  );
};

export default Sidebar;
