import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import About from "./components/About";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Blogs from "./components/Blogs";

function App() {
  return (
    <Router>
      <div style={{ display: "flex", height: "100vh" }}>
        {/* Sidebar */}
        <Sidebar />

        {/* Main content */}
        <div style={{ marginLeft: "150px", padding: "20px", flexGrow: 1 }}>
          <Routes>
            <Route path="/" element={<h1>Home</h1>} />
            <Route path="/about" element={<About/>} />
            <Route path="/blogs" element={<Blogs/>}/>
            <Route path="/services" element={<Services/>} />
            <Route path="/contact" element={<Contact/>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
