import React from "react";
import { Link } from "react-router-dom";
import './Blogs.css'

const Blogs = () => {
  const blogList = [
    { id: 1, title: "Healthy lifestyle", description: "What you need to know for a healthy lifestyle.", link: "/blogs/react-basics" },
    { id: 3, title: "How to Gain Weight", description: "Control your intake calorie.", link: "/blogs/flexbox-guide" },
  ];

  return (
    <div className="blogs">
      <h1>Blogs</h1>
      <ul className="blog-list">
        {blogList.map((blog) => (
          <li key={blog.id} className="blog-item">
            <Link to={blog.link} className="blog-title">
              {blog.title}
            </Link>
            <p className="blog-description">{blog.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Blogs;
